<template>
  <div class="reception-enter-spike-depth">
    <div class="bgc">
      <div class="w">
        <div class="title-img tx1200" ref="transform1">
          <img src="@/assets/images/enter-image/page19.png" alt="">
        </div>
      </div>
      <div class="mark-box"></div>
    </div>
    <!-- 企业文化 -->
    <culture ref="cultureRef" :isView="cultureView"/>
    <!-- 发展历程 -->
    <development-history ref="developmentHistoryRef" :isView="developmentHistoryView"/>
    <!-- 荣誉资质 -->
    <honor ref="honorRef" :isView="honorView" :honourPic="honourPic"/>
    <!-- 员工风采 -->
    <elegant ref="elegantRef" :isView="elegantView" :staffDemeanorPic="staffDemeanorPic"/>
  </div>
</template>

<script>
import {
  onMounted, onUnmounted, reactive, toRefs,
} from 'vue';
import culture from './components/culture.vue';
import developmentHistory from './components/development-history.vue';
import honor from './components/honor.vue';
import elegant from './components/elegant.vue';
import { isElementInViewport, itemGsap } from '@/views/index/js/usePage';
import apis from '@/request/apis';

export default {
  components: {
    culture,
    developmentHistory,
    honor,
    elegant,
  },
  setup() {
    const data = reactive({
      pageCount: 4,
      cultureRef: null,
      developmentHistoryRef: null,
      honorRef: null,
      elegantRef: null,
      cultureView: false,
      developmentHistoryView: false,
      honorView: false,
      elegantView: false,
      honourPic: '',
      partnersPic: '',
      staffDemeanorPic: '',
      transform1: null,
    });

    const detail = async () => {
      const res = await apis.receptionEnterSpikeDepth.getDetail();
      const { honourPic, staffDemeanorPic } = res;
      data.honourPic = honourPic;
      data.staffDemeanorPic = staffDemeanorPic;
    };

    const scroll = () => {
      data.cultureView = isElementInViewport(data.cultureRef.$el);
      data.developmentHistoryView = isElementInViewport(data.developmentHistoryRef.$el);
      data.honorView = isElementInViewport(data.honorRef.$el);
      data.elegantView = isElementInViewport(data.elegantRef.$el);
    };
    let app = null;
    onMounted(async () => {
      app = document.querySelector('#app');
      app.addEventListener('scroll', scroll);
      scroll();
      itemGsap(data.transform1, {
        x: 0,
        opacity: 1,
        duration: 1,
        ease: 'none',
      });
      await detail();
    });
    onUnmounted(() => {
      app.removeEventListener('scroll', scroll);
      app.scrollTop = 0;
    });

    return {
      ...toRefs(data),
    };
  },
};
</script>

<style lang="scss" scoped>
.reception-enter-spike-depth {
  .bgc {
    width: 100vw;
    height: 36.458vw;
    background-image: url('~@/assets/images/enter-image/page01.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: 50% 50%;
    overflow: hidden;
    position: relative;
    .title-img {
      width: 64.063vw;
      height: 5.104vw;
      margin-top: 20.521vw;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        bottom: -1.354vw;
        height: 0.313vw;
        width: 6.979vw;
        left: 0;
        background-color: #fff;
      }
    }
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .mark-box {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      // background: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0), rgba(0,0,0,0.9));
      background-image: url('~@/assets/images/home-image/page99.png');
      background-position: 50% 100%;
      background-size: 100% 20%;
      background-repeat: no-repeat;
    }
  }
}
@media screen and (max-width: 768px) {
  .reception-enter-spike-depth {
    .bgc {
      height: 50vw;
      background-image: url('~@/assets/images/enter-image/page01.png');
      background-size: auto 100%;
      .title-img {
        margin-top: 34.521vw;
        margin-left: 4vw;
      }
    }
  }
}
</style>
