<template>
  <div class="elegant" ref="elegantRef">
    <div class="mark"></div>
    <div class="w content">
      <div class="title-img">
        <img src="@/assets/images/enter-image/page21.png" alt="">
      </div>

      <div class="item-box" ref="itemBoxRef1">
        <div class="item" v-for="(item, index) in staffDemeanorPic1" :key="index" @mouseenter="mouseenter(item)">
          <div class="img-box">
            <el-image fit="cover" :src="item" :preview-src-list="[item]" :z-index="99999" :hide-on-click-modal="true" :preview-teleported="true"/>
          </div>
        </div>
      </div>
      <div class="item-box" ref="itemBoxRef2">
        <div class="item" v-for="(item, index) in staffDemeanorPic2" :key="index" @mouseenter="mouseenter(item)">
          <div class="img-box">
            <el-image fit="cover" :src="item" :preview-src-list="[item]" :z-index="99999" :hide-on-click-modal="true" :preview-teleported="true"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  nextTick, onUnmounted, reactive, toRefs, watchEffect,
} from 'vue';

export default {
  props: {
    isView: {
      type: Boolean,
      default: false,
    },
    staffDemeanorPic: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const data = reactive({
      staffDemeanorPic1: [],
      staffDemeanorPic2: [],
      itemBoxRef1: null,
      itemBoxRef2: null,
      elegantRef: null,
      count: 0,
    });
    let timerId = null;
    let timerId2 = null;

    const scroll = () => {
      timerId = setInterval(() => {
        data.itemBoxRef1.scrollLeft += 1;
        if (data.itemBoxRef1.scrollLeft >= data.itemBoxRef1.scrollWidth / 2) {
          data.itemBoxRef1.scrollLeft = 0;
        }
      }, 10);
      timerId2 = setInterval(() => {
        data.itemBoxRef2.scrollLeft += 1;
        if (data.itemBoxRef2.scrollLeft >= data.itemBoxRef2.scrollWidth / 2) {
          data.itemBoxRef2.scrollLeft = 0;
        }
      }, 10);
    };
    const staffDemeanorPicImgs = (str) => {
      const arr = str.split(',');
      const before = arr.slice(0, parseInt(arr.length / 2, 10));
      data.staffDemeanorPic1 = [...before, ...before];
      const after = arr.slice(parseInt(arr.length / 2, 10));
      data.staffDemeanorPic2 = [...after, ...after];
      nextTick(() => {
        scroll();
      });
    };
    watchEffect(() => {
      if (props.isView) {
        data.count++;
      }
      if (props.staffDemeanorPic && props.isView && data.count === 1) {
        staffDemeanorPicImgs(props.staffDemeanorPic);
      }
    });
    const mouseenter = (url) => {
      data.elegantRef.style['background-image'] = `url(${url})`;
    };

    onUnmounted(() => {
      clearInterval(timerId);
      clearInterval(timerId2);
    });
    return {
      ...toRefs(data),
      mouseenter,
    };
  },
};
</script>

<style scoped lang="scss">
.elegant {
  width: 100vw;
  height: 46.875vw;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 100%;
  translate: all 1s;
  position: relative;
  .mark {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
    background-color: rgba(255, 255, 255, 0.9);
  }
  .content {
    overflow: hidden;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
    .title-img {
      width: 15vw;
      height: 5.521vw;
      margin-top: 5.313vw;
      margin-bottom: 2.24vw;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .item-box {
      display: flex;
      overflow: hidden;
      margin-bottom: 1.563vw;
      .item {
        width: 23.958vw;
        flex: 0 0 23.958vw;
        height: 13.854vw;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 1.719vw;
        .img-box {
          width: 100%;
          height: 100%;
          display: block;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .elegant {
    width: 100vw;
    height: 80vw;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: auto 100%;
    translate: all 1s;
    position: relative;
    overflow: hidden;
    .mark {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 0;
      background-color: rgba(255, 255, 255, 0.9);
    }
    .content {
      overflow: hidden;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 2;
      .title-img {
        width: 25vw;
        height: auto;
        margin-left: 4vw;
        margin-top: 5.313vw;
        margin-bottom: 2.24vw;
        img {
          display: block;
          height: 100%;
          object-fit: cover;
        }
      }

      .item-box {
        display: flex;
        overflow: hidden;
        margin-bottom: 1.563vw;
        .item {
          width: 43.958vw;
          flex: 0 0 43.958vw;
          height: 33.854vw;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 1.719vw;
          .img-box {
            width: 100%;
            height: 100%;
            display: block;
          }
        }
      }
    }
  }
}
</style>
