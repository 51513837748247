<template>
  <div class="culture">
    <div class="w content">
      <div class="title-img tx-600" ref="transformRef1">
        <img src="@/assets/images/enter-image/page07.png" alt="">
      </div>
      <div class="item-box">
        <div class="item" v-for="(item, index) in list" :key="index" ref="itemsRef" :class="[`ty${300 * (index + 1)}`]">
          <div class="icon-box">
            <img fit="cover" class="img1" lazy :src="item.img"/>
            <img fit="cover" class="img2" lazy :src="item.hoverImg"/>
          </div>
          <div class="text1">{{ item.title }}</div>
          <div class="text2">{{ item.text }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import img1 from '@/assets/images/enter-image/page08.png';
import img2 from '@/assets/images/enter-image/page09.png';
import img3 from '@/assets/images/enter-image/page10.png';
import img4 from '@/assets/images/enter-image/page11.png';
import img5 from '@/assets/images/enter-image/page22.png';
import img6 from '@/assets/images/enter-image/page23.png';
import img7 from '@/assets/images/enter-image/page24.png';
import img8 from '@/assets/images/enter-image/page25.png';
import { reactive, toRefs, watchEffect } from 'vue';
import { itemGsap } from '@/views/index/js/usePage';

export default {
  props: {
    isView: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const data = reactive({
      count: 0,
      transformRef1: null,
      itemsRef: [],
    });
    const list = [
      {
        title: '企业愿景',
        text: '智慧冷链服务美好生活',
        img: img6,
        hoverImg: img2,
      },
      {
        title: '使命',
        text: '提供智慧冷链全链路服务解决方案',
        img: img1,
        hoverImg: img5,
      },
      {
        title: '企业价值观',
        text: '诚信为本 责任为纲 团队致胜 科创致远',
        img: img3,
        hoverImg: img7,
      },
      {
        title: '经营理念',
        text: '求精、高效、共赢',
        img: img4,
        hoverImg: img8,
      },
    ];

    watchEffect(() => {
      if (props.isView) {
        data.count++;
      }
      if (props.isView && data.count === 1) {
        itemGsap(data.transformRef1, {
          x: 0,
          opacity: 1,
          duration: 0.5,
        });
        data.itemsRef.forEach((item, index) => {
          itemGsap(item, {
            y: 0,
            opacity: 1,
            duration: 0.4 + index * 0.1,
            ease: 'none',
          });
        });
      }
    });

    return {
      list,
      ...toRefs(data),
    };
  },
};
</script>

<style scoped lang="scss">
.culture {
  width: 100vw;
  height: 37.5vw;
  overflow: hidden;
  background-image: url('~@/assets/images/enter-image/page12.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: 50% 50%;
  .title-img {
    width: 20vw;
    height: 5.104vw;
    margin-bottom: 2.917vw;
    margin-top: 4.688vw;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .item-box {
    display: flex;
    justify-content: space-between;
    .item {
      width: 18.229vw;
      height: 20.833vw;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-bottom: 0.521vw solid transparent;
      transition: all 0.3s;
      padding: 0 2.188vw;
      .icon-box {
        width: 5.521vw;
        height: 5.521vw;
        background-color: #F0F8FC;
        border-radius: 50%;
        overflow: hidden;
        margin-top: 3.75vw;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          display: block;
          width: 2.604vw;
          height: 2.604vw;
          object-fit: cover;
        }
        .img1 {
          display: block;
        }
        .img2 {
          display: none;
        }
      }
      &:hover {
        border-bottom-color: var(--baseColor1);
      }
      &:hover .img1{
        display: none;
      }
      &:hover .img2{
        display: block;
      }
      &:hover .icon-box {
        background-color: var(--baseColor1);
      }
      .text1 {
        height: 1.667vw;
        font-size: 1.25vw;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: bold;
        color: #000000;
        line-height: 1.615vw;
        margin: 0.938vw 0px 1.563vw;
      }
      .text2 {
        height: 1.458vw;
        font-size: 1.04vw;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        color: #333333;
        line-height: 1.563vw;
        text-align: center;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .culture {
    height: auto;
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: 50% 50%;
    .title-img {
      width: 35vw;
      height: auto;
      margin-bottom: 2.917vw;
      margin-top: 4.688vw;
      margin-left: 4vw;
    }

    .item-box {
      justify-content: space-evenly;
      padding: 1.333vw;
      flex-wrap: wrap;
      .item {
        width: 45vw;
        height: 30vw;
        padding: 0 2.188vw;
        margin-bottom: 2vw;
        .icon-box {
          width: 10.667vw;
          height: 10.667vw;
          margin-top: 3.75vw;
          img {
            width: 5.333vw;
            height: 5.333vw;
          }
        }
        .text1 {
          height: auto;
          font-size: 3.2vw;
          line-height: 1.615vw;
          margin: 0.938vw 0px 1.563vw;
        }
        .text2 {
          margin-top: 1.333vw;
          font-size: 2.667vw;
          line-height: 3.467vw;
        }
      }
    }
  }
}
</style>
