<template>
  <div class="honor">
    <div class="w content">
      <div class="title-img">
        <img src="@/assets/images/enter-image/page05.png" alt="">
      </div>

      <div class="item-box" ref="itemBoxRef1">
        <div class="item" v-for="(item, index) in honourPic1" :key="index">
          <div class="img-box">
            <el-image fit="cover x-bgco" :src="item" :preview-src-list="[item]" :hide-on-click-modal="true" :preview-teleported="true"/>
          </div>
        </div>
      </div>
      <div class="item-box" ref="itemBoxRef2">
        <div class="item" v-for="(item, index) in honourPic2" :key="index">
          <div class="img-box">
            <el-image fit="cover x-bgco" :src="item" :preview-src-list="[item]" :hide-on-click-modal="true" :preview-teleported="true"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  nextTick, onUnmounted, reactive, toRefs, watchEffect,
} from 'vue';

export default {
  props: {
    isView: {
      type: Boolean,
      default: false,
    },
    honourPic: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const data = reactive({
      honourPic1: [],
      honourPic2: [],
      itemBoxRef1: null,
      itemBoxRef2: null,
      count: 0,
    });

    let timerId = null;
    let timerId2 = null;
    const scroll = () => {
      timerId = setInterval(() => {
        data.itemBoxRef1.scrollLeft += 1;
        if (data.itemBoxRef1.scrollLeft >= data.itemBoxRef1.scrollWidth / 2) {
          data.itemBoxRef1.scrollLeft = 0;
        }
      }, 10);
      timerId2 = setInterval(() => {
        data.itemBoxRef2.scrollLeft += 1;
        if (data.itemBoxRef2.scrollLeft >= data.itemBoxRef2.scrollWidth / 2) {
          data.itemBoxRef2.scrollLeft = 0;
        }
      }, 10);
    };
    const honourPicImgs = (str) => {
      const arr = str.split(',');
      const before = arr.slice(0, parseInt(arr.length / 2, 10));
      data.honourPic1 = [...before, ...before];
      const after = arr.slice(parseInt(arr.length / 2, 10));
      data.honourPic2 = [...after, ...after];
      nextTick(() => {
        scroll();
      });
    };
    watchEffect(() => {
      if (props.isView) {
        data.count++;
      }
      if (props.honourPic && props.isView && data.count === 1) {
        honourPicImgs(props.honourPic);
      }
    });

    onUnmounted(() => {
      clearInterval(timerId);
      clearInterval(timerId2);
    });
    return {
      ...toRefs(data),
    };
  },
};
</script>

<style scoped lang="scss">
.honor {
  width: 100vw;
  height: 47.344vw;
  background-image: url('~@/assets/images/enter-image/page04.png');
  background-position: 50% 50%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .content {
    overflow: hidden;
    .title-img {
      width: 26.771vw;
      height: 5.625vw;
      margin-top: 4.479vw;
      margin-bottom: 5.469vw;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .item-box {
      display: flex;
      overflow: hidden;
      margin-bottom: 1.25vw;
      .item {
        width: 14.74vw;
        flex: 0 0 14.74vw;
        height: 11.771vw;
        border: 0.052vw solid #BDBDBD;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 1.094vw;
        translate: all 0.3s;
        &:hover {
          border: 0.052vw solid var(--baseColor1);
        }
        .img-box {
          width: 7.292vw;
          height: 9.896vw;
          display: block;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .honor {
    width: 100vw;
    height: auto;
    background-image: url('~@/assets/images/enter-image/page04.png');
    background-position: 50% 50%;
    background-size: auto 100%;
    background-repeat: no-repeat;
    .content {
      overflow: hidden;
      .title-img {
        width: 35vw;
        height: auto;
        margin-top: 4.479vw;
        margin-bottom: 3.469vw;
        margin-left: 4vw;
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .item-box {
        display: flex;
        overflow: hidden;
        margin-bottom: 2.25vw;
        .item {
          width: 24.74vw;
          flex: 0 0 24.74vw;
          height: 21.771vw;
          border: 0.052vw solid #BDBDBD;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 2.094vw;
          translate: all 0.3s;
          &:hover {
            border: 0.052vw solid var(--baseColor1);
          }
          .img-box {
            width: 17.292vw;
            height: 19.896vw;
            display: block;
            img {
              display: block;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }
  }
}
</style>
