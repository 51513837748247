<template>
  <div class="development-history">
    <div class="w content">
      <div class="title-box">
        <div class="title-img tx-600" ref="transformRef1">
          <img src="@/assets/images/enter-image/page06.png" alt="">
        </div>
      </div>
      <div class="item-box">
        <div class="item" v-for="(item, index) in list" :key="index" ref="itemsRef" :class="[`ty${index + 6}00`]">
          <div class="date">{{ item.date }}</div>
          <div class="title">{{ item.title }}</div>
          <div class="text">
            {{ item.text }}
          </div>
          <div class="item-img">
            <el-image fit="cover" lazy :src="item.img"/>
          </div>
        </div>
      </div>
      <div class="phone-item-box">
        <div class="item" v-for="(item, index) in list" :key="index" ref="itemsRef" :class="[`ty${index + 6}00`]">
          <div class="item-img">
            <!-- <el-image fit="cover" lazy :src="item.img"/> -->
          </div>
          <div class="rihgt">
            <div class="date">{{ item.date }} {{ item.title }}</div>
              <div class="text">
                {{ item.text }}
              </div>
          </div>
        </div>
        <div class="bor" ref="borRef">
          <div class="bor-item bor-item1"></div>
          <div class="bor-item bor-item2"></div>
          <div class="bor-item bor-item3"></div>
          <div class="bor-item bor-item4"></div>
          <div class="bor-item bor-item5"></div>
          <div class="bor-item bor-item6"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import img1 from '@/assets/images/enter-image/item01.png';
import img2 from '@/assets/images/enter-image/page17.png';
import img3 from '@/assets/images/enter-image/page16.png';
import img4 from '@/assets/images/enter-image/page18.png';
import img5 from '@/assets/images/enter-image/page15.png';
import img6 from '@/assets/images/enter-image/page14.png';
import {
  onMounted, reactive, toRefs, watchEffect,
} from 'vue';
import { itemGsap } from '@/views/index/js/usePage';

export default {
  props: {
    isView: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const data = reactive({
      count: 0,
      transformRef1: null,
      itemsRef: [],
      borRef: null,
    });
    const list = [
      {
        date: '1987',
        title: '起源',
        text: '于1987年国家机械部直属11家定点国营制冷企业之一广州冷冻机厂成立深圳分公司“华新冷气”。1997年更名为“穗联冷气”。2001年国营体制改革“穗联冷气”开始市场化运作，“穗深”品牌正式诞生。',
        img: img1,
      },
      {
        date: '2001-2005',
        title: '创业期',
        text: '为机关食堂、农贸市场、社会餐饮等建造小型商用冷库1000+ 座。',
        img: img2,
      },
      {
        date: '2006-2010',
        title: '拓展期',
        text: '逐步形成品牌化运作，成功进入各大国际酒管公司冷库供应商品牌库提供商用冷库(高端酒店) 解决方案。',
        img: img3,
      },
      {
        date: '2011-2015',
        title: '发展期',
        text: '商用冷库(高端酒店)业务进入井喷期，累计承建10000+座，市场占有率行业领先。',
        img: img4,
      },
      {
        date: '2016-2020',
        title: '跨越期',
        text: '布局冷链产业园板块，自持物流高标冷仓突破20万+ ㎡。与生鲜电商头部企业战略合作，一站式智慧冷链解决方案逐步成型。',
        img: img5,
      },
      {
        date: '2021-至今',
        title: '高速期',
        text: '完成全国冷链产业园布局，14座高标冷库，业务覆盖全国10个核心城市，运营面积40万+ ㎡。穗深冷链数字化管理升级、科技赋理系统、ROM远程售后平台上线。荣获“国家高新技术企业”、“广东省专精特新中小企业”称号。',
        img: img6,
      },
    ];

    watchEffect(() => {
      if (props.isView) {
        data.count++;
      }
      if (props.isView && data.count === 1) {
        itemGsap(data.transformRef1, {
          x: 0,
          opacity: 1,
          duration: 0.5,
        });
        data.itemsRef.forEach((item, index) => {
          itemGsap(item, {
            y: 0,
            opacity: 1,
            duration: 0.3 + index * 0.2,
            ease: 'none',
          });
        });
      }
    });

    onMounted(() => {
      const item6 = data.itemsRef[6];
      const item7 = data.itemsRef[7];
      const item8 = data.itemsRef[8];
      const item9 = data.itemsRef[9];
      const item10 = data.itemsRef[10];
      const { offsetHeight: offsetHeight6 } = item6;
      const { offsetHeight: offsetHeight7 } = item7;
      const { offsetHeight: offsetHeight8 } = item8;
      const { offsetHeight: offsetHeight9 } = item9;
      const { offsetHeight: offsetHeight10 } = item10;
      const count = offsetHeight6 + offsetHeight7 + offsetHeight8 + offsetHeight9 + offsetHeight10;
      const w = document.body.offsetWidth;
      data.borRef.style.height = `${(w / 100) * 2.667 * 5 + count}px`;
      data.borRef.children[1].style.top = `${(w / 100) * 2.667 + offsetHeight6}px`;
      data.borRef.children[2].style.top = `${(w / 100) * 2.667 * 2 + offsetHeight6 + offsetHeight7}px`;
      data.borRef.children[3].style.top = `${(w / 100) * 2.667 * 3 + offsetHeight6 + offsetHeight7 + offsetHeight8}px`;
      data.borRef.children[4].style.top = `${(w / 100) * 2.667 * 4 + offsetHeight6 + offsetHeight7 + offsetHeight8 + offsetHeight9}px`;
      data.borRef.children[5].style.top = `${(w / 100) * 2.667 * 5 + offsetHeight6 + offsetHeight7 + offsetHeight8 + offsetHeight9 + offsetHeight10}px`;
    });
    return {
      list,
      ...toRefs(data),
    };
  },
};
</script>

<style scoped lang="scss">
.development-history {
  width: 100%;
  height: 52.813vw;
  background-image: url('~@/assets/images/enter-image/page03.png');
  background-position: 50% 50%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .content {
    position: relative;
    .title-box {
      position: absolute;
      top: 4.688vw;
      left: 0;
      color: #fff;
      .title-img {
        width: 26.042vw;
        height: 5.729vw;
        margin-top: 4.063vw;
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .item-box {
      display: flex;
      justify-content: space-between;
      .item {
        width: 11.406vw;
        height: 28.125vw;
        // background: linear-gradient(180deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.74) 7%, rgba(255,255,255,0.42) 41%, rgba(255,255,255,0.19) 69%, rgba(255,255,255,0.05) 89%, rgba(255,255,255,0) 100%);
        background: linear-gradient(180deg, rgba(255,255,255,0.3) 0%, rgba(255,255,255,0.2) 7%, rgba(255,255,255,0.1) 41%, rgba(255,255,255,0.09) 69%, rgba(255,255,255,0.05) 89%, rgba(255,255,255,0) 100%);
        color: #fff;
        padding: 0 0.938vw;
        border-left: 1px solid #fff;
        position: relative;
        padding-top: 1.25vw;
        &::after {
          content: '';
          position: absolute;
          width: 0.417vw;
          height: 3.958vw;
          background: #FFFFFF;
          left: -0.208vw;
          top: 0;
        }
        &::before {
          content: '';
          position: absolute;
          width: 0.833vw;
          height: 0.833vw;
          border-radius: 50%;
          background: #FFFFFF;
          left: -0.417vw;
          bottom: 0;
        }
        &:nth-child(1) {
          margin-top: 21.146vw;
        }
        &:nth-child(2) {
          margin-top: 18.021vw;
        }
        &:nth-child(3) {
          margin-top: 14.792vw;
        }
        &:nth-child(4) {
          margin-top: 11.771vw;
        }
        &:nth-child(5) {
          margin-top: 8.646vw;
        }
        &:nth-child(6) {
          width: 14.479vw;
          margin-top: 5.521vw;
        }
        .date {
          font-size: 1.458vw;
          line-height: 1.667vw;
          height: 1.667vw;
        }
        .title {
          font-size: 1.25vw;
          font-weight: bold;
          height: 1.667vw;
          line-height: 1.667vw;
          margin-top: 0.938vw;
        }
        .text {
          font-size: 0.833vw;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 1.458vw;
          margin-top: 0.729vw;
          margin-bottom: 0.729vw;
        }

        .item-img {
          width: 5.625vw;
          height: 4.583vw;
          img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
    .phone-item-box {
      display: none;
    }
  }
}
@media screen and (max-width: 768px) {
  .development-history {
    width: 100%;
    height: auto;
    background-image: url('~@/assets/images/enter-image/page03.png');
    background-position: 50% 50%;
    background-size: auto 100%;
    background-repeat: no-repeat;
    overflow: hidden;
    .content {
      position: relative;
      .title-box {
        position: relative;
        margin-top: 4.688vw;
        margin-left: 4vw;
        margin-bottom: 4vw;
        color: #fff;
        .title-img {
          width: 35vw;
          height: auto;
          margin-top: 4.063vw;
          img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
      .item-box {
        display: none;
      }
      .phone-item-box {
        display: block;
        position: relative;
        margin-top: 6.667vw;
        .item {
          width: 89vw;
          // height: 33.6vw;
          height: auto;
          padding: 2.4vw 2.667vw;
          display: flex;
          margin-bottom: 2.667vw;
          overflow: hidden;
          margin-left: 6vw;
          // background: linear-gradient(to right, rgba(255,255,255,0.3) 0%, rgba(255,255,255,0.2) 7%, rgba(255,255,255,0.1) 41%, rgba(255,255,255,0.09) 69%, rgba(255,255,255,0.05) 89%, rgba(255,255,255,0) 100%);
          background: linear-gradient(94deg, rgba(255,255,255,0.29) 0%, rgba(255,255,255,0.04) 100%);
          .item-img {
            width: 18.133vw;
            flex-shrink: 0;
            height: 100%;
            display: none;
            img {
              display: block;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .rihgt {
            margin-left: 0.733vw;
            .date {
              font-size: 3.2vw;
              font-weight: bold;
              line-height: 3.2vw;
              height: 3.2vw;
              // margin-top: 1.333vw;
              color: #fff;
            }
            .text {
              font-size: 1.6vw;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 4.4vw;
              margin-top: 0.729vw;
              margin-bottom: 0.729vw;
            }
          }
        }
        .bor {
          position: absolute;
          top: 0;
          height: calc(36.26vw * 5);
          width: 1px;
          background-color: #fff;
          left: 4vw;
          z-index: 5;
          .bor-item {
            width: 2.133vw;
            height: 2.133vw;
            border-radius: 50%;
            background-color: #fff;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: 0;
            &:nth-child(2) {
              top: 36.26vw;
            }
            &:nth-child(3) {
              top: calc(36.26vw * 2);
            }
            &:nth-child(4) {
              top: calc(36.26vw * 3);
            }
            &:nth-child(5) {
              top: calc(36.26vw * 4);
            }
            &:nth-child(6) {
              top: calc(36.26vw * 5);
            }
          }
        }
      }
    }
  }
}
</style>
